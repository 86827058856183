





































import { CBtn } from "@/models/utils/c-btn.interface";
import { CIcon } from "@/models/utils/c-icon.interface";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class CosmopolConfirmationDialog extends Vue {
  @VModel({ type: Boolean }) show: boolean;
  @Prop({ required: false, default: "", type: String })
  private title: string;
  @Prop({ required: false, default: "", type: String })
  private message: string;
  @Prop({ required: false, default: null, type: String })
  private confirmationBtnLabel: string;
  @Prop({ required: false, default: null, type: Object })
  private icon: Pick<CIcon, "name" | "fill">;
  @Prop({ required: false, default: "500", type: String })
  private width: string;

  private confirmationBtn: Omit<CBtn, "type"> = {
    label: "Salva",
    labelClass: "c-label-light",
    color: "var(--v-success-darken3)",
    icon: {
      name: "check",
      fill: "var(--v-light-base)",
      height: 24,
      width: 24,
      iconStyle: "mb-1",
    },
  };

  private mounted(): void {
    this.confirmationBtnLabel &&
      this.$set(this.confirmationBtn, "label", this.confirmationBtnLabel);
  }

  private onConfirmation(): void {
    this.show = false;
    this.$emit("on-confirmation");
  }
}
